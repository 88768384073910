import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.news-carousel-block');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.news-carousel-block__carousel'),
        {
          slidesPerView: 1.2,
          spaceBetween: 20,
          watchOverflow: true,

          navigation: {
            prevEl: carouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carouselBlock.querySelector('.swiper-button-next'),
          },

          breakpoints: {
            560: {
              slidesPerView: 1.6,
            },
            680: {
              slidesPerView: 2.1,
            },
            1080: {
              slidesPerView: 2.7,
            },
            1400: {
              slidesPerView: 3.281,
            },
          },
        }
      );
    });
  }
});
